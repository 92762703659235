@import 'styles/constants.scss';

.contact_form#dark{
	background-color: $dark_background;
	#form textarea{
		color: $light_p;
		box-shadow: none;
		background-color: $input_dark_background;
	}
	#form input{
		color: $light_h1;
		box-shadow: none;
		background-color: $input_dark_background;
	}
}
.contact_form#light{
	background-color: $light_background
}

.contact_form span{
	font-size: 12px;
}
.contact_form {
	width:100%;
	margin: auto;
	padding:5% 0;


}

.contact_form p{
  font-size: 30px;
}
#side-form li h5{
position: relative;
font-size: 20px;

}
#side-form li{
font-size: 20px;
}
#side-form i {
	color: #2c98f0;
	font-size: 25px;
	font-weight: 500;
	margin: auto 30px;
}
#submit-button{
	font-family: "Quicksand";
	color: #555;
	width: 100%;
	border: none;
	font-size: 20px;
	padding: 1em;
}
#form input{
	font-family: "Quicksand";
	color: #555;
	width: 100%;
	height: 50px;
	margin-bottom: 1em;
	border: none;
	font-size: 20px;
	padding-left: 10px;
	box-shadow: 0px 0px 3px 1px rgba(172, 172, 172, 0.616);
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	outline: none;
	border: none;
}
#form textarea{
	font-family: "Quicksand";
	color: #555;
	width: 100%;
	height: 100px;
	margin-bottom: 1em;
	border: none;
	font-size: 20px;
	padding: 10px;
	box-shadow: 0px 0px 3px 1px rgba(172, 172, 172, 0.616);
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	outline: none;
	border: none;
}

#form-container{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	font-family: "Quicksand";
	position: relative;
	
}
#form{
	width: 45%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	text-align: left;
}
#side-form{
	width: 45%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	position: relative;
	padding-right:5%;
	gap: 6em;
}

#form::before{
	content: '';
	width: 2px;
	height: 80%;
	background-color: #2c98f0;
	position: absolute;
	left: 47.5%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}
#submit-button:hover{
	background-color: #2c98f0;
	color: #fff;
}

#side-form{
	list-style: none;
}
#side-form li a{
	text-decoration: none;
	color: #2c98f0;
	
}
@media screen and (max-width:1024px){
	
	#form-container{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center	;
	
	}
	#form::before{
		display: none;
	}
	#side-form{
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
		position: relative;
		padding: 0;
		gap: 3em;

	}
	#form{
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		text-align: left;
		margin: 3em auto;
	}
	#side-form i {
		color: #2c98f0;
		font-size: 25px;
		font-weight: 500;
		margin: auto 30px auto 0;
	}
}
@media screen and (max-width:500px){
	#form{
		width: 100%;
	}
	#side-form{
		width: 100%;

	}
}
