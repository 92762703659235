@import 'styles/constants.scss';

.skill_box#light{
    background-color: $card_light_background;
}
.skill_box#dark{
    background-color: $card_dark_background;
    .percent .number h2{
        color: $light_h1;
    }
     .text{
        color: $light_h1;
    }
}
.skill_box{
    width: 200px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 20px #00000033;
    margin: 6em 2em;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.percent {
    position: relative;
    width: 120px;
    height: 120px;
}

.percent svg{
    position: relative;
    width: 120px;
    height: 120px;
}
.percent svg circle{
    width: 120px;
    height: 120px;
    fill: none;
    stroke-width: 10;
    stroke: #000;
    transform: translate(5px, 5px);
    -webkit-transform: translate(5px, 5px);
    -moz-transform: translate(5px, 5px);
    -ms-transform: translate(5px, 5px);
    -o-transform: translate(5px, 5px);
    stroke-dasharray: 315;
    stroke-dashoffset: 315;
    stroke-linecap: round;
    

}
.percent:hover .target{
    animation: show78 2s;
    -webkit-animation: show78 2s;
    stroke-dashoffset: calc(315 - (315 * 25) / 100) !important; 
}

.percent svg circle:nth-child(1) {
    stroke-dashoffset: 0;
    stroke: #f3f3f3;
    stroke-width: 9;
    
}
.percent svg circle:nth-child(2) {
    stroke-dashoffset: calc(315 - (315 * 95) / 100);
    transform: scaleX(-1)  rotate(-90deg) translate(15px, 15px);
    transform-origin: center;
    -webkit-transform: scaleX(-1)  rotate(-90deg) translate(15px, 15px);
    -moz-transform: scaleX(-1)  rotate(-90deg) translate(15px, 15px);
    -ms-transform: scaleX(-1)  rotate(-90deg) translate(15px, 15px);
    -o-transform: scaleX(-1)  rotate(-90deg) translate(15px, 15px);
    animation: show78 2s;
    -webkit-animation: show78 2s;
    transition: stroke-dashoffset 1s;
}
@keyframes show78 {
	from {
    	stroke-dashoffset:315;
	}
	to {
		stroke-dashoffset: calc(315 - (315 * 95) / 100); 
	}
}

@-webkit-keyframes show78 {
  from {
    -webkit-stroke-dashoffset: 315; 
  }
  to {
    -webkit-stroke-dashoffset: calc(315 - (315 * 95) / 100); 
  }
} 

.skill_box .percent .number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.skill_box .percent .number h2{
    font-size: 30px;
    
}
.skill_box .percent .number h2 span{
    font-size: 16px;

}
.skill_box .text{
    font-size: 20px;
    padding: 10px 0 0;
    color: #333;
    font-weight: 600;
    letter-spacing: 1px;
}
@media screen and (max-width:780px){
    .skill_box .percent .number {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
    }
    .percent svg circle:nth-child(2) {
        stroke-dashoffset: calc(315 - (315 * 95) / 100);
        transform: scaleX(-1)  rotate(-90deg) translate();
        transform-origin: center;
        -webkit-transform: scaleX(-1)  rotate(-90deg) translate();
        -moz-transform: scaleX(-1)  rotate(-90deg) translate();
        -ms-transform: scaleX(-1)  rotate(-90deg) translate();
        -o-transform: scaleX(-1)  rotate(-90deg) translate();
        animation: show78 2s;
        -webkit-animation: show78 2s;
        transition: stroke-dashoffset 1s;
}
    .percent svg circle{
        width: 120px;
        height: 120px;
        fill: none;
        stroke-width: 10;
        stroke: #000;
        stroke-dasharray: 315;
        stroke-dashoffset: 315;
        stroke-linecap: round;
}
    .percent {
        position: relative;
        width: 120px;
        height: 120px;
    }
    
    .percent svg{
        position: relative;
        width: 120px;
        height: 120px;
        margin: auto;
    }
}
