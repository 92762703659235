// ### Primary

// - Bright Blue: hsl(220, 98%, 61%)
// - Check Background: linear-gradient hsl(192, 100%, 67%) to hsl(280, 87%, 65%)

// ### Neutral

// ### Light Theme

// - Very Light Gray: hsl(0, 0%, 98%)
// - Very Light Grayish Blue: hsl(236, 33%, 92%)
// - Light Grayish Blue: hsl(233, 11%, 84%)
// - Dark Grayish Blue: hsl(236, 9%, 61%)
// - Very Dark Grayish Blue: hsl(235, 19%, 35%)

// ### Dark Theme

// - Very Dark Blue: hsl(235, 21%, 11%)
// - Very Dark Desaturated Blue: hsl(235, 24%, 19%)
// - Light Grayish Blue: hsl(234, 39%, 85%)
// - Light Grayish Blue (hover): hsl(236, 33%, 92%)
// - Dark Grayish Blue: hsl(234, 11%, 52%)
// - Very Dark Grayish Blue: hsl(233, 14%, 35%)
// - Very Dark Grayish Blue: hsl(237, 14%, 26%)

$active_status:hsl(220, 98%, 61%);
$light_h1:hsl(236, 33%, 92%);
$light_p:hsl(0, 0%, 47%);
$button_light:hsla(235, 32%, 92%, 0.5);
$button_dark:hsl(233, 25%, 6%);
$button_hover_light:hsl(235, 32%, 92%);
$dark_background:hsl(233, 25%, 6%);
$light_background:hsl(0, 0%, 98%);
$card_dark_background:hsl(235, 24%, 19%);
$card_light_background:hsl(0, 0%, 98%);
$edu_card_dark_background:hsl(233, 14%, 35%);
$edu_card_p_dark:hsl(236, 33%, 92%);
$edu_card_dark_active_background:hsl(234, 39%, 85%);
$input_dark_background:hsl(237, 14%, 26%);
$navLink_light_color:hsl(236, 9%, 61%);
$navLink_light_hover_color:rgb(97, 210, 255);
