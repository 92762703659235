@import 'styles/constants.scss';

.edu_container#dark{
	background-color: $dark_background;
	details > summary {
		background-color: $edu_card_dark_background;
		border: none;
		color: white;
		i {
			color: white;
		}
		i.fa-minus{
			display: none;
		}
	
	}
	
	details p {
		color: $edu_card_p_dark;
		margin: 0.5em 0 1em 0;
	}
	details[open] summary{
		background-color: $edu_card_dark_active_background;
		color: #333333;
		i{
			color: #333333;
		}
		i.fa-plus{
			display: none;
		}
		i.fa-minus{
			display: inline;
		}
	}
}

.edu_container#light{
	background-color: $light_background;

}
.edu_container span{
	font-size: 14px;
}
details p{
	font-size: 22px;
	border:1px solid #e6e6e6;
}

.edu_container{
	width: 100%;
    margin: auto;
}


details > summary {
	outline: none;
	width: 100%;
	height: 70px;
	background-color: #f2f3f7;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin:0.2em auto;
	padding: 1em;
	border: 1px solid #e6e6e6;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 1px;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	color: #333333;
	i {
		color: #333333;
		transition: all 0.5s ease-in-out;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
	}
	i.fa-minus{
		display: none;
	}

}

details[open] summary{
	background-color: #2c98f0;
	color: white;
	i{
		color: white;
	}
	i.fa-plus{
		display: none;
	}
	i.fa-minus{
		display: inline;
	}
}


details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
  -webkit-animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0%    {
	  opacity: 0;
	  transform: translateX(-150px);
	  -webkit-transform: translateX(-150px);
	  -moz-transform: translateX(-150px);
	  -ms-transform: translateX(-150px);
	  -o-transform: translateX(-150px);
}
  100%  {
	   opacity: 1;
	   transform: translateX(2em);
	   -webkit-transform: translateX(2em);
	   -moz-transform: translateX(2em);
	   -ms-transform: translateX(2em);
	   -o-transform: translateX(2em);
}
}


details p {
	padding: 1em;
}

details{
	margin: auto;
}
details summary::-webkit-details-marker {
	display:none;
  }
@media screen and (max-width:500px){
	details summary{
		font-size: 18px;
		padding:0.5em;
		gap: 10px;

	}
	details p {
		font-size: 16px;
		padding: 1em;
	}
}
