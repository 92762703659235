@import 'styles/constants.scss';

.typeWriter_Cursor{
  font-size: 90px;
  border-radius: 25px ;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  color:$light_h1;
}
.typeWriter{
  font-family:"Playfair Display";
  color: white;
  font-size: 80px;
  line-height: normal;
}

.slideShow_main_container{
  width: 100%;
  min-height: 850px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
  height: 100vh;
  padding-top: 8em;
  transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
}
.slideShow_main_container#dark{
	background-color: $dark_background;
}
.slideShow_main_container#light{
	background-color: $light_background;
  .typeWriter{
    color: $dark_background;
  }
  .typeWriter_Cursor{
    color: $dark_background;
  }
  .sectionOne{
    color: $dark_background;
  }
  .sectionOne a{
    border: 1px solid $button_dark;
    color: $button_dark;
  }
}
.img_container{
  width: 40%;
  .Markos1{
    width: 100%;
    height: 100%;
    pointer-events: none;

  }
}

.sectionOne a{
  letter-spacing: 2px;
  font-family: "quicksand";
  background-color: transparent;
  color: $button_light;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid $button_light;
  padding: 0.5em 1em;
  text-transform: uppercase;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

  .sectionOne{
    height: 100%;
    width:55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    text-align: left;
    z-index: 1000;
  }


.sectionOne p {
  font-size: 20px;
  width: 85%;
  flex-wrap: wrap;
  word-wrap: break-word;
  text-transform: uppercase;
  color: $light_p;
}



.sectionOne a:hover{
  color: $button_hover_light;
  border-color: $button_hover_light
}
.sectionOne a i{
  display: inline-block;
  opacity: 0;
  width: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.sectionOne a:hover i {
  opacity: 1;
  width: auto;
  padding-left: 1em;
}
@media screen and (max-width:1024px){
  .slideShow_main_container{
    width: 95%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 6em;
  }
  .img_container{
    width: 70%;
  }
  .sectionOne p {
    width: 95%;
    font-size: 16px;
  }
  .sectionOne{
    height: 100%;
    width:95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    text-align: center;
    z-index: 1000;
    margin-top: 2em;
  }
  .typeWriter_Cursor{
    font-size: 70px;
    border-radius: 25px ;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    color:$light_h1;
  }
  .typeWriter{
    line-height: normal;
    font-size: 60px;
  }
}
@media screen and (max-width:500px){
  .img_container{
    width: 100%;
    .Markos1{
      width: 100%;
      pointer-events: none;
  
    }
  }
  .typeWriter{
    font-family:"Playfair Display";
    color: white;
    font-size: 40px;
    line-height: normal;
  }
  .typeWriter_Cursor{
    font-size: 40px;
  }
  .sectionOne{
    width:100%;
    height: fit-content;
    margin-top: 5em;
  }
  .sectionOne p {
    width: 100%;
    font-size: 14px;
  }
  .slideShow_main_container{
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    height: 100vh;
  }
  .sectionOne a{
    width: 100%;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid $button_light;
    padding: 0.5em 0em;
    text-transform: uppercase;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
}
