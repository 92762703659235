
.media_list{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 1em;
	padding: 0 1em !important;
}
.socialMedia_icon{
	width: 35px;
	height: 35px;
	object-fit: contain;
}
