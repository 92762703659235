@import 'styles/constants.scss';

.main_exp_container#dark{
	background-color: $dark_background;
	#exp-container p{
		color: $light_p;
	}
	#exp-container span{
		color: $light_h1;
		
	}
}
.main_exp_container#light{
	background-color: $light_background;
}

.main_exp_container span{
	font-size: 12px;
}
.main_exp_container h1{
	font-size: 25px;
}

#exp-container span{
	font-family: "Quicksand";
	font-size: 25px;
	margin-left: 2.5em;
	
}
#exp-container i{
	left: 0;
	top: 0;
	position: absolute;
	font-size: 25px;
	border: 15px solid;
	margin: auto;
	color: white;
}
#exp-container p{
	letter-spacing: 0.5px;
	font-size: 20px;
	margin: auto;
	margin-left: 3em;
	margin-top: 1.5em;
	font-family: "Quicksand";
	color: rgba(0, 0, 0, 0.7);
}



#exp-container{
	padding: 2em;
	padding-bottom: 6em;
	position: relative;
}


.main_exp_container{
	width: 100%;
	line-height: 1.8;
    margin: auto;
}
@media screen and (max-width:768px){
	#exp-container{
		padding: 0;
		padding-bottom: 5em;
		position: static;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
	}
	#exp-container p{
		letter-spacing: 0;
		font-size: 16px;
		margin: auto;
		margin-left: 0;
		margin-top: 2em;
		font-family: "Quicksand";
		color: rgba(0, 0, 0, 0.7);
	}
	#exp-container i{
		position: static;
		font-size: 20px;
		border: 15px solid;
		margin: 0;
		margin-bottom: 20px;
		color: white;
	}
	#exp-container span{
		font-family: "Quicksand";
		font-size: 20px;
		margin: 0;
		
	}
}
