@import 'styles/constants.scss';


.whoami_container#dark{
	background-color: $dark_background;
	#about-section-container p{
		font-size: 18px;
		color: $light_p;
		strong{
			color: $light_h1;
		}
	}
	.sectionThree_icons div{
		color: $light_h1;
	}
	
	
}
.whoami_container#light{
	background-color: $light_background;

}
.HireMe_button{
	color: #000;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: 1px solid #000;
	padding: 5px 80px;
	background-color: transparent;
	font-weight: 500;
	font-size: 25px;
	line-height: 2;
	font-family: "Quicksand";
	position: absolute;
	right: 35px;
	bottom: 30px;
  }
  .HireMe_button:hover{
	background-color: black;
	color: white;
	border: none;
  }

#about-section-container p{
	font-size: 18px;
}



.sectionFour{
	width: 100%;
	height: 250px;
	margin: 2em auto;
	background-color: #f9bf3f;
	border-radius: 5px;
	font-family: "Playfair Display";
	padding: 2em;
	position: relative;
}
.sectionFour p{
	line-height: 1.6;
	color: #000;
	font-family: "Playfair Display",Georgia,serif;
	font-weight: 400;
	font-size: 30px;
}
#about-section-container h1{
	font-size: 30px;
}


#about-section-container{
	width: 100%;
	margin: 10px auto;
	font-family: "Quicksand";
	color: rgba(0,0,0,.7);
}
.sectionThree_icons{
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
  	justify-content: center;
	gap: 100px;
	margin:6em auto;
}

.sectionThree_icons .dev_icon{
	font-size: 20px;
	text-align: center;
	font-family: "Quicksand";
	flex-basis: 200px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	gap: 30px;
	hr{
		opacity: 0.8;
		width: 100%;
		height: 1px;
		margin: auto;
	}
}

.sectionThree_icons .dev_icon i{
	font-size: 35px;
}
@media screen and (max-width:600px){
	.sectionThree_icons .dev_icon{
		padding: 0 1em;
	}
	.sectionFour{
		width: 100%;
		height: 250px;
		margin: 2em auto;
		background-color: #f9bf3f;
		border-radius: 5px;
		font-family: "Playfair Display";
		padding: 1.5em;
		position: static;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		text-align: left;
	}
	.sectionFour p{
		line-height: 1.6;
		color: #000;
		font-family: "Playfair Display",Georgia,serif;
		font-weight: 400;
		font-size: 22px;
	}
	.HireMe_button{
		width: 100%;
		color: #000;
		text-transform: uppercase;
		letter-spacing: 1px;
		border: 1px solid #000;
		padding: 5px 0;
		background-color: transparent;
		font-weight: 500;
		font-size: 25px;
		line-height: 2;
		font-family: "Quicksand";
		position: static;
	  }
	  .HireMe_button:hover{
		background-color: black;
		color: white;
		border: none;
	  }
}
