
@import 'styles/constants.scss';

.skills_container#dark{
	background-color: $dark_background;
  .skills_para{
    color: $light_p;
}
}
.skills_container#light{
	background-color: $light_background;

}



  .skills_container span{
    font-size: 14px;
  }
 .skills_container h1{
    font-size: 25px;
}
.skills_para{
    font-family: "Quicksand";
    color: rgba(0, 0, 0, 0.7);
    font-size: 22px;
    line-height: 1.8;
    padding: 0 1em;
}



.skills_container{
	width: 100%;
	margin: auto;
}
@media screen and (max-width:1024px){
  .skills_para{
    font-family: "Quicksand";
    color: rgba(0, 0, 0, 0.7);
    font-size: 100%;
    line-height: 1.8;
    padding: 0;
}
}
