@import 'styles/constants.scss';


.offcanvas-menu {
	> i.toggle-open {
		top: 30px;
		left: 30px;
		position: fixed;
		width: 40px;
		height: 40px;
		cursor: pointer;
		transition:all 0.5s ease-in-out;
		display: flex;
		align-items: center;
		z-index: 10;
		-webkit-transition:all 0.5s ease-in-out;
		-moz-transition:all 0.5s ease-in-out;
		-ms-transition:all 0.5s ease-in-out;
		-o-transition:all 0.5s ease-in-out;
		font-size: 40px;
		color: white;
		@media screen and (min-width:1270px){display: none;}
}
	nav {
		position: fixed;
		height: 100%;
		width: 450px;
		left: -1000px;
		overflow: auto;
		background: #ffffff;
		transition: 0.3s ease;
		padding: 40px 0;
		z-index: 100000;
		border-right: 2px solid #333;
		&.dark{
			background-color: $dark_background;
			#list-items hr{
				width: 80%;
				height: 1px;
				color: $light_h1;
				margin:1em auto;
			}
			> div.about_section {
				display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: auto;
					text-align: center;
				 h3{
					font-size: 26px;
					color: $light_h1;
				}
				 h6{
					font-size: 14px;
					color: $light_p
				}

			}
			#copyright-text-container{
				color: $light_p;
				font-size: 15px;
				width: 90%;
				line-height: 2;
				font-family:"Quicksand";
				text-align: center;
				margin: auto;
			}
		}
		&.light{
			background-color: $light_background;
			#list-items hr{
				width: 80%;
				height: 1px;
				color: black;
				margin:1em auto;
			}
			> div.about_section {
				display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: auto;
					text-align: center;
					h3{
						font-size: 26px;
					}
					 h6{
						font-size: 14px;
					}
			}
			#copyright-text-container{
				font-size: 15px;
				width: 90%;
				line-height: 2;
				font-family:"Quicksand";
				text-align: center;
				margin: auto;
			}
		}
		> i.toggle-close{
			position: absolute;
			right: 30px;
			top: 30px;
			font-size: 40px;
			cursor: pointer;
		}
		#list-items ul li a{
			color: $navLink_light_color;
			
		}
		#list-items ul li a:hover{
			color: $navLink_light_hover_color;
			border-bottom: solid 1px $navLink_light_hover_color;
		}
	}


		nav#show {
			left: 0;
		}
}



@media screen and (max-width:500px){
	.offcanvas-menu{
		nav {
			position: fixed;
			height: 100%;
			width: 90%;
			left: -1000px;
			overflow: auto;
			background: #ffffff;
			transition: 0.3s ease;
			padding: 40px 0;
			z-index: 100000;
		 }
	}
}
