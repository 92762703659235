@import './constants.scss';


@mixin h1 {
	font-family:"Playfair Display" !important;
	margin-bottom: 3em;
	margin-top: 1em;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1.8;
	letter-spacing: 5px;
	font-size: 25px;
}
@mixin span {
	font-family:"Quicksand" !important;
	font-weight: 400;
	letter-spacing: 5px;
	text-transform: uppercase;
}
.section_h1_light{
	color:black;
	@include h1();
	
}
.section_span_light{
	color: rgba(0, 0, 0, 0.7);
	@include span();
	
}
.section_h1_dark{
	@include h1();
	color:$light_h1;
}
.section_span_dark{
	color:$light_p;
	@include span();

}
