@import 'styles/constants.scss';

.loadMoreWork_button {
  width: 100%;
  height: 80px;
  background-color: #008CBA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "quicksand";
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 28px;
  margin: auto;
  margin-top: 5em;
}
.main_work_container{
	width: 100%;
	position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.main_work_container#dark{
  background-color: $dark_background;
}
.main_work_container#light{
  background-color: $light_background;
}
#work-container{
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 50px;
	
}
.main_work_container span{
	font-size: 16px;
}
.main_work_container p{
	font-size: 30px;
}
.loadMoreWork_button:hover{
	background-color: #00aae2;
}
@media screen and (max-width:500px){
  .loadMoreWork_button {
    width: 100%;
    height: 60px;
    background-color: #008CBA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "quicksand";
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 20px;
    margin: auto;
    margin-top: 2em;
    text-transform: uppercase;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
}
