@import 'styles/constants.scss';


.content_container{
    width: 100%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5em;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    overflow: hidden;
}
.indented_container{
    padding: 0 5%;
    width: 100%;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    gap: 15em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.content_container#dark{
    background-color: $dark_background;
}
.content_container#light{
    background-color: $light_background;
}
@media screen and (min-width:1270px){
    .content_container{
        width: 75%;
        float: right;
    }
}
