

 figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    width: 400px;
    height: 290px;
    background: #333333;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
	box-shadow: 0px 5px 15px -3px rgba(0,0,0,0.3);
}

 figure img {
	min-height: 100%;
	max-width: 100%;
	opacity: 1;
    object-fit: contain;
}

 figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

 figure figcaption::before,
 figure figcaption::after {
	pointer-events: none;
}

 figure figcaption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

 figure figcaption > div > a > img {
	z-index: 1000;
    width: 40px;
    height: 40px;
    
}

 figure h2 {
    font-size: 18px;
	font-family: "Lato";
	font-weight: 700 ;
	text-transform: capitalize;
}



 figure h2{
	margin: 0;
}

 figure p {
	width: 80%;
	letter-spacing: 1px;
	font-size: 18px;
	margin: auto;
}


figure.effect-zoe figcaption {
	top: auto;
	bottom: 0;
	padding: 1em;
	padding-right: 2em;
	height: 3.75em;
	background: #fff;
	color: #3c4a50;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,100%,0);
	transform: translate3d(0,100%,0);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

figure.effect-zoe h2 {
	float: left;
}





figure.effect-zoe p.description {
	position: absolute;
	top: -10em;
	left: 50%;
	transform: translate(-50%);
	color: #fff;
	text-transform: none;
	font-size: 16px !important;
	opacity: 0;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate(-50%);
	-moz-transform: translate(-50%);
	-ms-transform: translate(-50%);
	-o-transform: translate(-50%);
}

figure.effect-zoe h2,
figure.effect-zoe p.icon-links a {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,200%,0);
	transform: translate3d(0,200%,0);
}







figure.effect-zoe:hover p.description {
	opacity: 1;
}

figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2,
figure.effect-zoe:hover p.icon-links a {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-zoe:hover h2 {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}





figure.effect-zoe:hover {
    background-color: black;

}
figure.effect-zoe:hover img{
    opacity: 0.3;

}

@media screen and (max-width:500px){
     figure {
        position: relative;
        float: left;
        overflow: hidden;
        margin: 10px 0;
		min-height: 240px;
        width: 100%;
        height: auto;
        background: #333333;
        text-align: center;
        cursor: pointer;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        margin: auto;
    }
	 figure img {
		min-height: 240px;
		height: 100%;
		width: 100%;
		opacity: 1;
		object-fit: contain;

	}
	figure.effect-zoe p.description {
		position: absolute;
		top: -8em;
		left: 50%;
		transform: translate(-50%);
		color: #fff;
		text-transform: none;
		font-size: 16px !important;
		opacity: 0;
		-webkit-transition: opacity 0.35s;
		transition: opacity 0.35s;
		-webkit-backface-visibility: hidden;
		-webkit-transform: translate(-50%);
		-moz-transform: translate(-50%);
		-ms-transform: translate(-50%);
		-o-transform: translate(-50%);
	}
}
@media screen and (max-width:320px){
	figure {
        position: relative;
        float: left;
        overflow: hidden;
        margin: 10px 0;
		min-height: fit-content;
        width: 100%;
        height: auto;
        background: #333333;
        text-align: center;
        cursor: pointer;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        margin: auto;
    }
	figure img {
		min-height: auto;
		height: 100%;
		width: 100%;
		opacity: 1;
		object-fit: contain;

	}
}
