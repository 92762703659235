@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'styles/constants.scss';


.services_container{
	width: 100%;
	margin: auto;
}

.services_container#dark{
	background-color: $dark_background;
	.icon-sec h5{
		color: $light_h1;
	}
	.icon-sec p{
		color: $light_p;
	}
}
.services_container#light{
	background-color: $light_background;

}


.services_container p{
	font-size: 25px;
}
.services_container span{
	font-size: 16px;
}
.poly1, .poly2, .poly3, .poly4, .poly5, .poly6{
	// clip-path: path("M75,37.2 128.8,68.4 128.8,131.2 75,162.4 21,130.8 21,68.4");
	height: 200px;
	width: 150px;
	position: relative;


}
#poly-container{
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 50px;
	
}
#poly-container div{
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#poly-container div p{
	width: 90%;
	height: 100px;
	margin: auto;
	font-size: 18px;
	text-align: center;
	line-height: 30px;
	font-family: "Quicksand";
	display: flex;
	align-items: center;
	justify-content: center;

}
#poly-container i{
	font-size: 26px;
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}



.poly1{
	background:#2c98f0;	
}
.poly2{
	background:#ec5453;
}
.poly3{
	background:#f9bf3f;
}
.poly4{
	background:#a84cb8;
}
.poly5{
	background:#2fa499;
}
.poly6{
	background:#4054b2;	
}



.icon-sec{
	position: relative;
	font-family: "Quicksand";
	border-bottom: 1.5px solid;
	
}



.s1{
	border-color:#2c98f0;
}

.s2{
	border-color:#ec5453;
}

.s3{
	border-color:#f9bf3f;
}

.s4{
	border-color:#a84cb8;
}

.s5{
	border-color:#2fa499;
}

.s6{
	border-color:#4054b2;
}
@media screen and (max-width:320px){
	#poly-container{
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		gap: 50px;
		
	}
}
