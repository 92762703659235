@import 'styles/constants.scss';


.aside_container{
	width: 25%;
	overflow-y:auto;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	height: 100%;
}
.aside_container#dark{
	background-color: $dark_background;
	
	#about-intro h3{
		color: $light_h1;
	}
	#about-intro h6{
		color: $light_p;
	}
	#copyright-text-container{
		color: $light_p
	}
	#list-items ul li a{
		color: $navLink_light_color;
		
	}
	#list-items ul li a:hover{
		color: $navLink_light_hover_color;
		border-bottom: solid 1px $navLink_light_hover_color;
	}
}
.aside_container#light{
	#list-items hr{
		width: 70%;
		height: 1px;
		color: black;
		margin:1em auto;
	}
	background-color: $light_background;
}
#about-intro{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family:"Playfair Display";
}
#list-items hr{
	width: 70%;
	height: 1px;
	color: $light_h1;
	margin:1em auto;
}

#about-intro h3{
	font-size: 30px;
}

.profile-img{
	width: 200px;
	height: 200px;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	overflow: hidden;
	padding-right: 10px;
	margin: 2em;
}


#list-items ul li{
	
	line-height:2.5em;
}


#list-items{
	width: 100%;
	margin: auto;
	ul{
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		list-style: none;
		padding: 0;
	}
}



#list-items ul li a{
	text-decoration: none;
	font-family:"Quicksand";
	font-size: 18px;
	color: rgba(70, 70, 70, 0.7);
    margin: auto;
	
}

#list-items ul li a:focus{
	color: black;
}


#list-items ul li a:hover{
	color: black;
}

#copyright-text-container{
	font-size: 18px;
	width: 90%;
	line-height: 2em;
	font-family:"Quicksand";
	text-align: center;
	margin: auto;
}


#list-items ul li a.active{
	color: $navLink_light_hover_color !important;
	border-bottom: solid 1px $navLink_light_hover_color;
	transition: ease-in 0.3s;
}

#list-items a.active:hover{
	color: $navLink_light_hover_color;
}




.hvr-underline-from-center {
	display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
	color: rgba(0, 0, 0, 0.7);
}

#about-intro h6 {
	font-family: "Quicksand";
	font-size: 1em;
	text-align: center;
}
.aside_container::-webkit-scrollbar {
	width: 10px;
  }
.aside_container::-webkit-scrollbar-track{
	background-color: white;
	box-shadow: inset 0 0 5px grey; 
	border-radius: 10px;
  }
.aside_container::-webkit-scrollbar-thumb {
	background: black; 
	border-radius: 10px;
  }
  


@media screen and (max-width:1270px){
	.aside_container{
		display: none;
	
	}
}
