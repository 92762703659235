.mycontainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}



:root {
  --hue: 240;
  --first-color: hsl(var(--hue), 16%, 18%);
  --first-color-alt: hsl(var(--hue), 16%, 12%);
  --title-color: hsl(var(--hue), 8%, 15%);
  --text-color: hsl(var(--hue), 8%, 35%);
  --body-color: hsl(var(--hue), 100%, 99%);
  --container-color: #FFF;

  --body-font: 'Poppins', sans-serif;
  --big-font-size: 1.5rem;
  --normal-font-size: .938rem;

  --z-modal: 1000;
}

@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 1.75rem;
    --normal-font-size: 1rem;
  }
}
.modal__container{
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  display: grid;
  align-items: flex-end;
  overflow: hidden;
  transition: all .3s;
  z-index: 10000000;
  visibility: hidden;
  opacity: 0;
  -webkit-backdrop-filter: blur(2px);

}

.modal__content{
  position: relative;
  background-color: var(--container-color);
  text-align: center;
  padding: 3rem 2rem 2rem;
  border-radius: 1rem 1rem 0 0;
  transition: all .3s;
  transform: translateY(10%);
}


.modal__close{
  display: inline-flex;
  background-color: var(--first-color);
  border-radius: 100%;
  color: #FFF;
  font-size: 20px;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  padding: 0.3em 0.5em;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.modal__title{
  font-size: var(--big-font-size);
  color: var(--title-color);
  font-weight: 500;
}



.modal__button-width{
  width: 90%;
}

.modal__button-link{
  display: block;
  margin: 1rem auto 0;
  background-color: transparent;
  color: var(--first-color);
  font-weight: 500;
}

.show-modal{
  visibility: visible;
  opacity: 1;
}

.show-modal .modal__content{
  transform: translateY(0);
}
@media screen and (min-width: 576px){
  .modal__content{
    margin: auto;
    width: 380px;
    border-radius: 1.25rem;
  }

  .modal__img{
    width: 170px;
  }
}





